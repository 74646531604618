import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import MainNav from "./MainNav"
import ReginaLogo from "../assets/images/regina-logo-white.svg"

const Header = ({ siteTitle, menuLinks, path }) => {
  // Scroll animation
  if (typeof window !== "undefined") {
    const bodyStyle = document.body.style
    let setScroll = () => {
      bodyStyle.setProperty(
        "--scroll",
        window.pageYOffset / (document.body.offsetHeight - window.innerHeight)
      )
    }
    window.addEventListener("scroll", setScroll, false)
    setScroll()
  }

  return (
    <header
      id="header"
      className="container site-header"
    >
      <Link to="/">
        <img
          className="site-header__logo"
          src={ReginaLogo}
          alt={siteTitle}
        />
      </Link>

      <MainNav menuLinks={menuLinks} path={path} />
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string
}

Header.defaultProps = {
  siteTitle: ``,
  menuLinks: [],
}

export default Header
